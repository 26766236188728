import { motion } from "framer-motion";
import './footer.css'

const Footer = () => {


    return (
        <motion.div>
             {/* Код для компонента */}
        </motion.div>
    )
}

export default Footer